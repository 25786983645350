import { Label, Switch } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { PriceQuoteUnit } from '@Constants/PriceQuoteUnit';
import { usePriceQuoteUnit } from '@Core/prices/usePriceQuoteUnit';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';

const isTotal = (unit: PriceQuoteUnit): unit is 'TOTAL' => unit === 'TOTAL';
interface PriceQuoteSwitchProps {
  perPersonTitle?: string;
  totalTitle?: string;
}

export const PriceQuoteSwitch: React.FC<PriceQuoteSwitchProps> = ({
  perPersonTitle = 'pricing.perPerson',
  totalTitle = 'pricing.totalPrice',
}) => {
  const { t } = useTranslation();
  const { priceQuoteUnit, setPriceQuoteUnit } = usePriceQuoteUnit();
  const isTotalUnit = isTotal(priceQuoteUnit);
  const quoteUnitLabel = isTotalUnit ? t('pricing.totalPrice') : t('pricing.perPerson');

  const onChange = () => {
    const value = isTotalUnit ? 'PER_PERSON' : 'TOTAL';
    setPriceQuoteUnit(value);
    trackEventClick('filterPriceSelection', value);
  };

  return (
    <WithClickTracking
      useDataTracking={false}
      action="filterPriceSelection"
      label={quoteUnitLabel}
    >
      <label
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={onChange}
      >
        <Label
          variant={['medium', null, 'small']}
          sx={{
            color: 'textDimmedheavy',
          }}
        >
          {t(perPersonTitle)}
        </Label>
        <Switch
          aria-label={t('priceQuoteToggle')}
          data-id="price-quote-unit-toggle"
          checked={isTotalUnit}
          onChange={onChange}
          sx={{
            marginX: '3xs',
          }}
        />
        <Label
          variant={['medium', null, 'small']}
          sx={{
            color: 'textDimmedheavy',
          }}
        >
          {t(totalTitle)}
        </Label>
      </label>
    </WithClickTracking>
  );
};
